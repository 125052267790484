import React from "react";
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Sidebar from '../Components/leftsidebar';
import Topbar from '../Components/topbar';
import Breadcrumb from '../Components/breadcrumb';
import Footer from '../Components/footer';
import axios from "axios";

const CompanyDetailsPage = () => {
    const { id } = useParams();
    const [companyData, setCompanyData] = useState([]);
    const [editedCompany, setEditedCompany] = useState({
        _id: id, // Include technician's _id for backend update
        comments: "",
        rejectionreason: ""
    });

    useEffect(() => {
        viewblogID();
    }, []);

    const viewblogID = () => {
        fetch(`https://api.gadgetsreborn.com/company/${id}`, {
            method: "GET",
        })
            .then((res) => res.json())
            .then((companydata) => {
                console.log(companydata);
                setCompanyData(companydata);
            });
    };

    const handleVerify = async (id) => {
        console.log(id, window.localStorage.getItem("token"))
        await axios.get(`https://api.gadgetsreborn.com/companyVerify/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "bearer " + window.localStorage.getItem("token"),
            }
        })
            .then(response => {
                // Access the file content from the response data
                console.log(response);
                alert(response.data.message);
                window.location.reload()
            })
            .catch(error => {
                console.error('Error retrieving file content:', error.message);
            });
    };

    const handleReject = async (id) => {
        console.log(id, window.localStorage.getItem("token"))
        await axios.get(`https://api.gadgetsreborn.com/companyRejected/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "bearer " + window.localStorage.getItem("token"),
            }
        })
            .then(response => {
                // Access the file content from the response data
                console.log(response);
                alert(response.data.message);
                window.location.reload()
            })
            .catch(error => {
                console.error('Error retrieving file content:', error.message);
            });
    };

    const handleUpdateCompany = () => {
        // Assuming editedCompany contains the _id and updated fname
        const { _id, comments, rejectionreason } = editedCompany;

        fetch("https://api.gadgetsreborn.com/updateCompanysingle", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                _id,
                comments,
                rejectionreason,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                alert("Update Done");
                window.location.reload()
            });
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedCompany({ ...editedCompany, [name]: value });
    };



    return (
        <div>
            <div id="wrapper">
                <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div className="box">
                        <svg width="1038" height="861" viewBox="0 0 1038 861" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="-19" y="-159.085" width="1119" height="1119" rx="65" transform="rotate(-21.7644 -19 -159.085)" fill="#FDDF02"></rect>
                        </svg>
                    </div>
                    <div id="content">
                        <Topbar />
                        <div className="container-fluid">
                            <Breadcrumb />
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="card shadow mb-4">
                                        <div
                                            className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h6 className="m-0 font-weight-bold text-primary">Company Details</h6>
                                            {companyData.isverified ? (
                                                <div className="d-flex">
                                                    <div className="bg-success px-3 mr-3 py-2 rounded text-white text-center">Verified</div>
                                                    <div className="btn btn-danger text-center px-3 py-2 rounded text-white" onClick={() => handleReject(companyData._id)}>Reject</div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className="btn btn-warning text-center px-3 py-2 rounded text-white" onClick={() => handleVerify(companyData._id)}>Verify</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="card-body">
                                            <table className="table table-striped">
                                                <tbody className="text-dark">

                                                    <tr>
                                                        <th width="50%">Organization Id:</th>
                                                        <td width="50%">{companyData.organizationId}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Company Name:</th>
                                                        <td>{companyData.companyname}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Company Email:</th>
                                                        <td>{companyData.companyemail}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Company Type: </th>
                                                        <td>{companyData.companytype}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Company Address:</th>
                                                        <td>{companyData.companyaddress}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Owner Name:</th>
                                                        <td>{companyData.ownername}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Owner Emirates Id:</th>
                                                        <td> {companyData.owneremiratesId}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Phone:</th>
                                                        <td> {companyData.phone}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Trade License No:</th>
                                                        <td>{companyData.tradelicenseno}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Trade License Exp.:</th>
                                                        <td>{companyData.tradelicenseexp}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Region:</th>
                                                        <td>{companyData.region}</td>
                                                    </tr>                    <tr>
                                                        <th>Owner Passport No:</th>
                                                        <td>{companyData.ownerpassportno}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Contact Person Name:</th>
                                                        <td>{companyData.contactpersonname}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Contact Person Phone Number:</th>
                                                        <td>{companyData.contactpersonno}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Company Location:</th>
                                                        <td>{companyData.companylocation}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Verified:</th>
                                                        <td>{companyData.isverified ? 'Yes' : 'No'}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Comments:</th>
                                                        <td>{companyData.comments}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Reject Reason:</th>
                                                        <td>{companyData.rejectionreason}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Document Status:</th>
                                                        <td>{companyData.documentstatus ? 'Uploaded' : 'Not Available'}</td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-6 col-lg-6">
                                    <div className="card shadow mb-4">
                                        <div
                                            className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h6 className="m-0 font-weight-bold text-primary">Documents</h6>
                                        </div>
                                        <div className="card-body">
                                            {companyData && companyData.verificationDoc ? (
                                                <div id="playground">
                                                    <div class="image-set m-t-20">
                                                        <>
                                                            {companyData.verificationDoc.Passport && (
                                                                <Link className="pr-3" data-magnify="gallery" data-src="" data-caption="Passport" data-group="a" to={companyData.verificationDoc.Passport}>
                                                                    <img src={companyData.verificationDoc.Passport} width="100" alt="Passport" />
                                                                </Link>
                                                            )}
                                                            {companyData.verificationDoc.EmiratesID && (
                                                                <Link className="pr-3" data-magnify="gallery" data-src="" data-caption="Emirates ID" data-group="a" to={companyData.verificationDoc.EmiratesID}>
                                                                    <img src={companyData.verificationDoc.EmiratesID} width="100" alt="Emirates ID" />
                                                                </Link>
                                                            )}
                                                            {companyData.verificationDoc.TradeLicence && (
                                                                <Link className="pr-3" data-magnify="gallery" data-src="" data-caption="Trade Licence" data-group="a" to={companyData.verificationDoc.TradeLicence}>
                                                                    <img src={companyData.verificationDoc.TradeLicence} width="100" alt="Trade Licence" />
                                                                </Link>
                                                            )}
                                                            {companyData.verificationDoc.Photograph && (
                                                                <Link className="pr-3" data-magnify="gallery" data-src="" data-caption="Photograph" data-group="a" to={companyData.verificationDoc.Photograph}>
                                                                    <img src={companyData.verificationDoc.Photograph} width="100" alt="Photograph" />
                                                                </Link>
                                                            )}
                                                        </>
                                                    </div>
                                                </div>
                                            ) : (
                                                <p>No Documents Uploaded</p>
                                            )}

                                            <h3 className="pt-4">Add Comments &amp; Reason</h3>

                                            {editedCompany && (
                                                <div>
                                                    <form>
                                                        {/* <div className="form-group text-dark">
                                                            <label>ID</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="id"
                                                                value={companyData.organizationId}
                                                                readOnly
                                                            />
                                                        </div> */}
                                                        <div className="form-group">
                                                            <label>Comments</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="comments"
                                                                value={editedCompany.comments}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Rejection Reason</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="rejectionreason"
                                                                value={editedCompany.rejectionreason}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="btn btn-warning text-center px-3 py-2 rounded text-white" onClick={handleUpdateCompany}>Update</div>
                                                    </form>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default CompanyDetailsPage;
