import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../Components/leftsidebar';
import Topbar from '../Components/topbar';
import Breadcrumb from '../Components/breadcrumb';
import Footer from '../Components/footer';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const Alldefects = () => {
    const [repairs, setRepairs] = useState([]);
    const [editedRepair, setEditedRepair] = useState({});
    const [searchKeyword, setSearchKeyword] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        fetchRepairs();
    }, []);

    const fetchRepairs = async () => {
        try {
            const response = await axios.get('https://api.gadgetsreborn.com/getrepairs');
            setRepairs(response.data);
        } catch (error) {
            console.error('Error fetching defects:', error);
        }
    };

    const handleOpenEditModal = (defect) => {
        setEditedRepair(defect); // Initialize the editedRepair state with the selected defect data
        setIsEditing(true);
    };

    const handleCloseModal = () => {
        setIsEditing(false);
        setEditedRepair({});
    };

    const handleInputChange = (e) => {
        // Update the editedRepair state when input fields change
        const { name, value } = e.target;
        setEditedRepair({ ...editedRepair, [name]: value });
    };

    const handleUpdateRepair = async (repairId) => {
        try {
            const response = await axios.post('https://api.gadgetsreborn.com/updateRepair', editedRepair);
            console.log(response.data);
            fetchRepairs();
            setIsEditing(false);
            setEditedRepair({}); // Clear edited defect state
        } catch (error) {
            console.error('Error updating repair:', error);
        }
    };

    const handelDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this entry?")) {
            axios
                .delete(`https://api.gadgetsreborn.com/deleteRepair/${id}`)
                .then((res) => {
                    window.location.reload();
                })
                .catch((error) => console.log(error));
        }
    };

    //Search Filter
    const filteredRepairs = repairs.filter(repair =>
        repair.type.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        repair.brand.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        repair.series.seriesname.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        repair.model.modelname.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        repair.defect.defectname.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        repair.repairmin.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        repair.repairmax.toLowerCase().includes(searchKeyword.toLowerCase())
    );


    const handleEditAmount = (repairId, field) => {
        const updatedRepairs = repairs.map(defect => {
            if (defect._id === repairId) {
                return { ...defect, [field]: editedRepair[field] };
            }
            return defect;
        });
        setRepairs(updatedRepairs);
    };

    return (

        <div>

            <div id="wrapper">
                <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div className="box">
                        <svg width="1038" height="861" viewBox="0 0 1038 861" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="-19" y="-159.085" width="1119" height="1119" rx="65" transform="rotate(-21.7644 -19 -159.085)" fill="#FDDF02"></rect>
                        </svg>
                    </div>
                    <div id="content">
                        <Topbar />
                        <div className="container-fluid">
                            <Breadcrumb />

                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card shadow mb-4">
                                        <div
                                            className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h6 className="m-0 font-weight-bold text-primary">All Defects</h6>
                                            <div className="w-25">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search..."
                                                    value={searchKeyword}
                                                    onChange={(e) => setSearchKeyword(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Type</th>
                                                        <th>Brand</th>
                                                        <th>Series</th>
                                                        <th>Model</th>
                                                        <th>Defect Name</th>
                                                        <th>Min. Amount</th>
                                                        <th>Max. Amount</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredRepairs.map(repair => (
                                                        <tr key={repair._id}>
                                                            <td>{repair.type.name}</td>
                                                            <td>{repair.brand.name}</td>
                                                            <td>{repair.series.seriesname}</td>
                                                            <td>{repair.model.modelname}</td>
                                                            <td>{repair.defect.defectname}</td>
                                                            <td>
                                                                {isEditing && editedRepair._id === repair._id ? (
                                                                    <input
                                                                        type="number"
                                                                        value={editedRepair.repairmin}
                                                                        onChange={handleInputChange}
                                                                        name="repairmin"
                                                                    />
                                                                ) : (
                                                                    <span onDoubleClick={() => handleOpenEditModal(repair)}>
                                                                        {repair.repairmin} AED
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                {isEditing && editedRepair._id === repair._id ? (
                                                                    <input
                                                                        type="number"
                                                                        value={editedRepair.repairmax}
                                                                        onChange={handleInputChange}
                                                                        name="repairmax"
                                                                    />
                                                                ) : (
                                                                    <span onDoubleClick={() => handleOpenEditModal(repair)}>
                                                                        {repair.repairmax} AED
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                {editedRepair._id === repair._id ? (
                                                                    <>
                                                                        <button className="btn btn-primary mr-2" onClick={() => handleUpdateRepair(repair._id)}>
                                                                            Update
                                                                        </button>
                                                                        <button className="btn btn-secondary" onClick={() => setEditedRepair({})}>
                                                                            Cancel
                                                                        </button>
                                                                    </>
                                                                ) : (
                                                                    <div className="row">
                                                                        {/* <div className="col-3">
                                                                            <Link to="#" className="text-dark cursor-pointer">
                                                                                <i className="fas fa-edit text-dark cursor-pointer" />
                                                                            </Link>
                                                                        </div> */}
                                                                        <div className="col-3">
                                                                            <button className="btn btn-link text-danger p-0" onClick={() => handelDelete(repair._id)}>
                                                                                <i className="fas fa-trash text-danger" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

            <Link className="scroll-to-top rounded" to="#page-top">
                <i className="fas fa-angle-up" />
            </Link>



        </div>
    )
}

export default Alldefects;
