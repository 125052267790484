import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../Components/leftsidebar';
import Topbar from '../Components/topbar';
import Breadcrumb from '../Components/breadcrumb';
import Footer from '../Components/footer';
import axios from 'axios';

const AddAlldevice = () => {

    const [types, setTypes] = useState([]);
    const [brands, setBrands] = useState([]);
    const [seriess, setSeriess] = useState([]);
    const [models, setModels] = useState([]);
    const [defects, setDefects] = useState([]);
    const [toggleSwitch, setToggleSwitch] = useState(false);
    const [typeName, setTypeName] = useState('');
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [seriesname, setSeriesname] = useState('');
    const [brand, setBrand] = useState('');
    const [modelname, setModelname] = useState('');
    const [series, setSeries] = useState('');
    const [defectname, setDefectname] = useState('');
    const [model, setModel] = useState('');
    const [defect, setDefect] = useState('');
    const [repairmin, setRepairmin] = useState('');
    const [repairmax, setRepairmax] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedSeries, setSelectedSeries] = useState('');
    const [selectedModal, setSelectedModal] = useState('');
    const [selectedDefect, setSelectedDefect] = useState('');


    // Fetch types on component mount
    useEffect(() => {
        fetchTypes();
    }, []);

    // Fetch brands when selectedType changes
    useEffect(() => {
        if (selectedType) {
            fetchBrandsByType(selectedType);
        }
    }, [selectedType]);

    // Fetch series when selectedBrand changes
    useEffect(() => {
        if (selectedBrand) {
            fetchSeriesByBrand(selectedBrand);
        }
    }, [selectedBrand]);

    // Fetch models when selectedSeries changes
    useEffect(() => {
        if (selectedSeries) {
            fetchModelsBySeries(selectedSeries);
        }
    }, [selectedSeries]);

    // Fetch defects when selectedModal changes
    useEffect(() => {
        if (selectedModal) {
            fetchDefectsByModal(selectedModal);
        }
    }, [selectedModal]);

    // Fetch types
    const fetchTypes = async () => {
        try {
            const response = await axios.get('https://api.gadgetsreborn.com/gettypes');
            setTypes(response.data);
        } catch (error) {
            console.error('Error fetching types:', error);
            // Add logic to handle error, such as displaying an error message to the user
        }
    };

    // Fetch brands by type
    const fetchBrandsByType = async (typeId) => {
        try {
            const response = await axios.get(`https://api.gadgetsreborn.com/getbrands/${typeId}`);
            setBrands(response.data);
        } catch (error) {
            console.error('Error fetching brands:', error);
        }
    };

    // Fetch series by brand
    const fetchSeriesByBrand = async (brandId) => {
        try {
            const response = await axios.get(`https://api.gadgetsreborn.com/getseries/${brandId}`);
            setSeriess(response.data);
        } catch (error) {
            console.error('Error fetching types:', error);
        }
    };

    // Fetch models by series
    const fetchModelsBySeries = async (seriesId) => {
        try {
            const response = await axios.get(`https://api.gadgetsreborn.com/getmodels/${seriesId}`);
            setModels(response.data);
        } catch (error) {
            console.error('Error fetching models:', error);
        }
    };

    // Fetch defects by modal
    const fetchDefectsByModal = async (modalId) => {
        try {
            const response = await axios.get(`https://api.gadgetsreborn.com/getdefects/${modalId}`);
            setDefects(response.data);
        } catch (error) {
            console.error('Error fetching defects:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://api.gadgetsreborn.com/types', { name: typeName });
            console.log(response.data);

            if (response.status === 201) {
                alert('Type Added Successfully'); // Show alert if status is 201 (Created)
            } else {
                alert('Failed to add type'); // Show alert for other response statuses
            }

            // Display success message using alert
            // window.alert('Type added successfully');
            setTypeName('');
        } catch (error) {
            console.error(error);
            window.alert('Something went wrong');
            // window.alert('Error adding type: ' + error.message);
        }
    };


    const handleSubmitBrand = async (e) => {
        e.preventDefault();
        try {
            if (!name || !type) {
                throw new Error('Name and Type are required');
            }

            const response = await axios.post('https://api.gadgetsreborn.com/mobilebrands', { name, type });
            console.log(response.data);

            if (response.status === 201) {
                alert('Brand Added Successfully');
            } else {
                alert('Failed to add brand');
            }

            setName('');
            setType('');
        } catch (error) {
            console.error('Error adding brand:', error);
            if (error.response && error.response.status === 400) {
                window.alert('Brand name already exists and is connected with the provided type');
            }
        }
    };

    const handleSubmitSeries = async (e) => {
        e.preventDefault();
        try {
            if (!seriesname || !brand || !type) {
                throw new Error('Series name, brand, and type are required');
            }

            const response = await axios.post('https://api.gadgetsreborn.com/series', { seriesname, brand, type });
            console.log(response.data);

            if (response.status === 201) {
                alert('Series Added Successfully'); // Show alert if status is 201 (Created)
            } else {
                alert('Failed to add Series'); // Show alert for other response statuses
            }

            // Clear form fields after successful submission
            setSeriesname('');
            setBrand('');
            setType('');
        } catch (error) {
            console.error('Error adding series:', error);
            if (error.response && error.response.status === 400) {
                // Series with the same name, brand, and type already exists
                window.alert('Series with the same name, brand, and type already exists');
            }
        }
    };


    const handleSubmitModal = async (e) => {
        e.preventDefault();
        try {
            if (!modelname || !series || !brand || !type) {
                alert('Modal Name, Type, Brand, and Series are required');
                return;
            }

            const response = await axios.post('https://api.gadgetsreborn.com/modals', { modelname, series, brand, type });
            console.log(response.data);

            if (response.status === 201) {
                alert('Modal Added Successfully'); // Show alert if status is 201 (Created)
            } else {
                alert('Failed to add Modal'); // Show alert for other response statuses
            }

            setModelname('');
            setType('');
            setBrand('');
            setSeries('');
        } catch (error) {
            console.error('Error adding modal:', error);
            alert('Error adding modal. Please try again.');
        }
    };

    const handleSubmitdefect = async (e) => {
        e.preventDefault();
        try {
            if (!defectname || !model || !type || !brand || !series) {
                alert('Defect Min. Amount, Defect Max. Amount, Defect Name, Model, Type, Brand and Series are required');
            }

            const response = await axios.post('https://api.gadgetsreborn.com/defects', { defectname, model, type, brand, series }); // Include type, brand, series, modal here
            console.log(response.data);

            if (response.status === 201) {
                alert('Defect Added Successfully'); // Show alert if status is 201 (Created)
            } else {
                alert('Failed to add defect'); // Show alert for other response statuses
            }

            // Clear form fields after successful submission
            setDefectname('');
            setModel('');
            setType('');
            setBrand('');
            setSeries('');
        } catch (error) {
            // console.error('Error adding defect:', error);
            alert("Something went wrong");
        }
    };


    const handleSubmitrepair = async (e) => {
        e.preventDefault();
        try {
            if (!repairmin || !repairmax || !defect || !model || !type || !brand || !series) {
                alert('Repair Min. Amount, Repair Max. Amount, Defect Name, Model, Type, Brand and Series are required');
                return;
            }

            const response = await axios.post('https://api.gadgetsreborn.com/repairminmax', {
                repairmin,
                repairmax,
                defect,
                model,
                type,
                brand,
                series
            });

            // Display success or failure message
            if (response.status === 201) {
                alert('Repair Amount Added Successfully');
            } else {
                alert('Failed to add repair amount');
            }

            // Clear form fields after submission
            setRepairmin('');
            setRepairmax('');
            setDefect('');
            setModel('');
            setType('');
            setBrand('');
            setSeries('');
        } catch (error) {
            console.error('Error adding repair amount:', error);
            alert('Something went wrong');
        }
    };

    return (

        <div>
            <div id="wrapper">
                <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div className="box">
                        <svg width="1038" height="861" viewBox="0 0 1038 861" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="-19" y="-159.085" width="1119" height="1119" rx="65" transform="rotate(-21.7644 -19 -159.085)" fill="#FDDF02"></rect>
                        </svg>
                    </div>
                    <div id="content">
                        <Topbar />
                        <div className="container-fluid">
                            <Breadcrumb />
                            <div className="row">
                                <div className="col-xl-3 ml-auto text-right">
                                    <label className="checkbox-inline text-dark">
                                        <input type="checkbox" data-toggle="toggle" data-on="Enabled" data-off="Disabled" onChange={() => setToggleSwitch(!toggleSwitch)} /> Show All Forms
                                    </label>
                                </div>

                                {toggleSwitch && (
                                    <div className="col-xl-12">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="card shadow mb-4">
                                                    <div
                                                        className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                                        <h6 className="m-0 font-weight-bold text-primary">Add Types</h6>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-xl-12">
                                                                <form>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="typeName">Type Name:</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="typeName"
                                                                            value={typeName}
                                                                            onChange={(e) => setTypeName(e.target.value)}
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Add Type</button>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="card shadow mb-4">
                                                    <div
                                                        className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                                        <h6 className="m-0 font-weight-bold text-primary">Add Brands</h6>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-xl-12">
                                                                <form onSubmit={handleSubmitBrand}>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="type">Type:</label>
                                                                        <select id="type" className="form-control" value={type} onChange={(e) => { setType(e.target.value); setSelectedType(e.target.value); }}>
                                                                            <option value="">Select Type</option>
                                                                            {types.map(type => (
                                                                                <option key={type._id} value={type._id}>{type.name}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="name">Name:</label>
                                                                        <input type="text" className="form-control" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                                                                    </div>

                                                                    <button type="submit" className="btn btn-primary">Add Brand</button>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="card shadow mb-4">
                                                    <div
                                                        className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                                        <h6 className="m-0 font-weight-bold text-primary">Add Series</h6>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-xl-12">
                                                                <form onSubmit={handleSubmitSeries}>

                                                                    <div className="mb-3">
                                                                        <label htmlFor="type">Type:</label>
                                                                        <select id="type" className="form-control" value={type} onChange={(e) => { setType(e.target.value); setSelectedType(e.target.value); }}>
                                                                            <option value="">Select Type</option>
                                                                            {types.map(type => (
                                                                                <option key={type._id} value={type._id}>{type.name}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="brand">Brands:</label>
                                                                        <select className="form-control" id="brand" value={brand} onChange={(e) => setBrand(e.target.value)}>
                                                                            <option value="">Select Brand</option>
                                                                            {brands.map(brand => (
                                                                                <option key={brand._id} value={brand._id}>{brand.name}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="seriesname">Name:</label>
                                                                        <input type="text" className="form-control" id="seriesname" value={seriesname} onChange={(e) => setSeriesname(e.target.value)} />
                                                                    </div>
                                                                    <button type="submit" className="btn btn-primary">Add Series</button>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="card shadow mb-4">
                                                    <div
                                                        className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                                        <h6 className="m-0 font-weight-bold text-primary">Add Modals</h6>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-xl-12">
                                                                <form onSubmit={handleSubmitModal}>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="type">Type:</label>
                                                                        <select id="type" className="form-control" value={type} onChange={(e) => { setType(e.target.value); setSelectedType(e.target.value); }}>
                                                                            <option value="">Select Type</option>
                                                                            {types.map(type => (
                                                                                <option key={type._id} value={type._id}>{type.name}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="brand">Brand:</label>
                                                                        <select id="brand" className="form-control" value={brand} onChange={(e) => { setBrand(e.target.value); setSelectedBrand(e.target.value); }}>
                                                                            <option value="">Select Brand</option>
                                                                            {brands.map(brand => (
                                                                                <option key={brand._id} value={brand._id}>{brand.name}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="series">Series:</label>
                                                                        <select className="form-control" id="series" value={series} onChange={(e) => setSeries(e.target.value)}>
                                                                            <option value="">Select Series</option>
                                                                            {seriess.map(series => (
                                                                                <option key={series._id} value={series._id}>{series.seriesname}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="modelname">Modal Name:</label>
                                                                        <input className="form-control" type="text" id="modelname" value={modelname} onChange={(e) => setModelname(e.target.value)} />
                                                                    </div>
                                                                    <button type="submit" className="btn btn-primary">Add Modal</button>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="card shadow mb-4">
                                                    <div
                                                        className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                                        <h6 className="m-0 font-weight-bold text-primary">Add Defects</h6>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-xl-12 pb-3">
                                                                <form onSubmit={handleSubmitdefect}>
                                                                    <div className="d-flex justify-content-row">
                                                                        <div className="mb-3 w-50 mr-3">
                                                                            <label htmlFor="type">Type:</label>
                                                                            <select id="type" className="form-control" value={type} onChange={(e) => { setType(e.target.value); setSelectedType(e.target.value); }}>
                                                                                <option value="">Select Type</option>
                                                                                {types.map(type => (
                                                                                    <option key={type._id} value={type._id}>{type.name}</option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                        <div className="mb-3 w-50">
                                                                            <label htmlFor="brand">Brand:</label>
                                                                            <select id="brand" className="form-control" value={brand} onChange={(e) => { setBrand(e.target.value); setSelectedBrand(e.target.value); }}>
                                                                                <option value="">Select Brand</option>
                                                                                {brands.map(brand => (
                                                                                    <option key={brand._id} value={brand._id}>{brand.name}</option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex justify-content-row">
                                                                        <div className="mb-3 mr-3 w-50">
                                                                            <label htmlFor="series">Series:</label>
                                                                            <select id="series" className="form-control" value={series} onChange={(e) => { setSeries(e.target.value); setSelectedSeries(e.target.value); }}>
                                                                                <option value="">Select Series</option>
                                                                                {seriess.map(series => (
                                                                                    <option key={series._id} value={series._id}>{series.seriesname}</option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                        <div className="mb-3 w-50">
                                                                            <label htmlFor="series">Modal:</label>
                                                                            <select className="form-control" id="model" value={model} onChange={(e) => setModel(e.target.value)}>
                                                                                <option value="">Select Modal</option>
                                                                                {models.map(model => (
                                                                                    <option key={model._id} value={model._id}>{model.modelname}</option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="defectname">Defect Name:</label>
                                                                        <input className="form-control" type="text" id="defectname" value={defectname} onChange={(e) => setDefectname(e.target.value)} />
                                                                    </div>
                                                                    <button type="submit" className="btn btn-primary mt-3">Add Defect</button>
                                                                </form>
                                                            </div>
                                                            {/* <div className="col-xl-12">
                                                    <ul>
                                                        {defects.map(defect => (
                                                            <li key={defect._id}>
                                                                Type: {defect.type.name}, Brand: {defect.brand.name}, Series: {defect.series.seriesname}, Modal:{defect.model.modelname}, Defect: {defect.defectname}</li>
                                                        ))}
                                                    </ul>
                                                </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card shadow mb-4">
                                        <div
                                            className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h6 className="m-0 font-weight-bold text-primary">Add Repair Cost</h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xl-12 pb-3">
                                                    <form onSubmit={handleSubmitrepair}>
                                                        <div className="d-flex justify-content-row">
                                                            <div className="mb-3 w-50 mr-3">
                                                                <label htmlFor="type">Type:</label>
                                                                <select id="type" className="form-control" value={type} onChange={(e) => { setType(e.target.value); setSelectedType(e.target.value); }}>
                                                                    <option value="">Select Type</option>
                                                                    {types.map(type => (
                                                                        <option key={type._id} value={type._id}>{type.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="mb-3 w-50">
                                                                <label htmlFor="brand">Brand:</label>
                                                                <select id="brand" className="form-control" value={brand} onChange={(e) => { setBrand(e.target.value); setSelectedBrand(e.target.value); }}>
                                                                    <option value="">Select Brand</option>
                                                                    {brands.map(brand => (
                                                                        <option key={brand._id} value={brand._id}>{brand.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-row">
                                                            <div className="mb-3 mr-3 w-50">
                                                                <label htmlFor="series">Series:</label>
                                                                <select id="series" className="form-control" value={series} onChange={(e) => { setSeries(e.target.value); setSelectedSeries(e.target.value); }}>
                                                                    <option value="">Select Series</option>
                                                                    {seriess.map(series => (
                                                                        <option key={series._id} value={series._id}>{series.seriesname}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="mb-3 w-50">
                                                                <label htmlFor="Modal">Modal:</label>
                                                                <select id="model" className="form-control" value={model} onChange={(e) => { setModel(e.target.value); setSelectedModal(e.target.value); }}>
                                                                    <option value="">Select Modal</option>
                                                                    {models.map(model => (
                                                                        <option key={model._id} value={model._id}>{model.modelname}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="defect">Defect:</label>
                                                            <select id="defect" className="form-control" value={defect} onChange={(e) => { setDefect(e.target.value); setSelectedDefect(e.target.value); }}>
                                                                <option value="">Select Defect</option>
                                                                {defects.map(defect => (
                                                                    <option key={defect._id} value={defect._id}>{defect.defectname}</option>
                                                                ))}
                                                            </select>
                                                        </div>

                                                        <div>
                                                            <label htmlFor="defectname">Add Min/Max Amount:</label>
                                                            <div className="d-flex justify-content-row">
                                                                <div className="mr-4 w-50">
                                                                    <label htmlFor="defectname"><small>Min. Amount:</small></label>
                                                                    <div className="input-group">
                                                                        <input className="form-control" type="number" id="repairmin" value={repairmin} onChange={(e) => setRepairmin(e.target.value)} />
                                                                        <div className="input-group-append">
                                                                            <span className="input-group-text">AED</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="w-50">
                                                                    <label htmlFor="defectname"><small>Max. Amount:</small></label>
                                                                    <div className="input-group">
                                                                        <input className="form-control" type="number" id="repairmax" value={repairmax} onChange={(e) => setRepairmax(e.target.value)} />
                                                                        <div className="input-group-append">
                                                                            <span className="input-group-text">AED</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button type="submit" className="btn btn-primary mt-3">Submit</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <Link className="scroll-to-top rounded" to="#page-top">
                <i className="fas fa-angle-up" />
            </Link>
        </div>
    )
}

export default AddAlldevice
