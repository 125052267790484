import React from "react";
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Sidebar from '../Components/leftsidebar';
import Topbar from '../Components/topbar';
import Breadcrumb from '../Components/breadcrumb';
import Footer from '../Components/footer';
import axios from "axios";

const TechnicianDetailsPage = () => {

    const { id } = useParams();
    const [technicianData, setTechnicianData] = useState([]);
    const [editedTechnician, setEditedTechnician] = useState({
        _id: id, // Include technician's _id for backend update
        comments: "",
        rejectionreason: ""
    });

    useEffect(() => {
        viewTechnicianByID();
    }, []);

    const viewTechnicianByID = () => {
        fetch(`https://api.gadgetsreborn.com/technician/${id}`)
            .then((res) => res.json())
            .then((technicianData) => {
                console.log(technicianData);
                setTechnicianData(technicianData);
            })
            .catch((error) => console.log(error));
    };

    const handleVerify = async (id) => {
        console.log(id, window.localStorage.getItem("token"));
        try {
            const response = await axios.get(`https://api.gadgetsreborn.com/technicianVerify/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": "bearer " + window.localStorage.getItem("token"),
                }
            });
            console.log(response);
            alert(response.data.message);
        } catch (error) {
            console.error('Error retrieving file content:', error.message);
        }
    };

    const handleUpdateTechnician = () => {
        // Assuming editedTechnician contains the _id and updated fname
        const { _id, comments, rejectionreason } = editedTechnician;

        fetch("https://api.gadgetsreborn.com/updateTechniciansingle", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                _id,
                comments,
                rejectionreason,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                alert("Update Done");
                window.location.reload()
            });
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedTechnician({ ...editedTechnician, [name]: value });
    };


    return (
        <div>
            <div id="wrapper">
                <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div className="box">
                        <svg width="1038" height="861" viewBox="0 0 1038 861" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="-19" y="-159.085" width="1119" height="1119" rx="65" transform="rotate(-21.7644 -19 -159.085)" fill="#FDDF02"></rect>
                        </svg>
                    </div>
                    <div id="content">
                        <Topbar />
                        <div className="container-fluid">
                            <Breadcrumb />
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="card shadow mb-4">
                                        <div
                                            className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h6 className="m-0 font-weight-bold text-primary">Technician Details</h6>
                                            {technicianData.isverifiedtech ?
                                                <div className="bg-success px-3 py-2 rounded text-white text-center">Verified</div>
                                                : <div className="btn btn-warning text-center px-3 py-2 rounded text-white" onClick={() => handleVerify(technicianData._id)}>Verify</div>
                                            }
                                        </div>
                                        <div className="card-body">
                                            <table className="table table-striped">
                                                <tbody className="text-dark">

                                                    <tr>
                                                        <th width="50%">Technician Id:</th>
                                                        <td width="50%">{technicianData.technicianId}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Username:</th>
                                                        <td>{technicianData.username}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Name: </th>
                                                        <td>{technicianData.fname}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Email:</th>
                                                        <td>{technicianData.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Phone:</th>
                                                        <td>{technicianData.phone}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Emirates Id:</th>
                                                        <td> {technicianData.emiratesId}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Passport No:</th>
                                                        <td> {technicianData.passportno}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>User Type:</th>
                                                        <td>{technicianData.userType}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Status:</th>
                                                        <td>{technicianData.isActive ? 'Yes' : 'No'}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Verified:</th>
                                                        <td>{technicianData.isverifiedtech ? 'Yes' : 'No'}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Comments:</th>
                                                        <td>{technicianData.comments}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Reject Reason:</th>
                                                        <td>{technicianData.rejectionreason}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Document Status:</th>
                                                        <td>{technicianData.techdocumentstatus ? 'Uploaded' : 'Not Available'}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Availability:</th>
                                                        <td>{technicianData.availability ? 'Available' : 'Not Available'}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Organization:</th>
                                                        <td>{technicianData.isOrganization ? technicianData.organizationId : 'Freelance'}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-6 col-lg-6">
                                    <div className="card shadow mb-4">
                                        <div
                                            className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h6 className="m-0 font-weight-bold text-primary">Documents</h6>
                                        </div>
                                        <div className="card-body">
                                            {technicianData && technicianData.verificationDoc ? (
                                                <div id="playground">
                                                    <div class="image-set m-t-20">
                                                        <>
                                                            {technicianData.verificationDoc.Passport && (
                                                                <Link className="pr-3" data-magnify="gallery" data-src="" data-caption="Passport" data-group="a" to={technicianData.verificationDoc.Passport}>
                                                                    <img src={technicianData.verificationDoc.Passport} width="100" alt="Passport" />
                                                                </Link>
                                                            )}
                                                            {technicianData.verificationDoc.EmiratesID && (
                                                                <Link className="pr-3" data-magnify="gallery" data-src="" data-caption="Emirates ID" data-group="a" to={technicianData.verificationDoc.EmiratesID}>
                                                                    <img src={technicianData.verificationDoc.EmiratesID} width="100" alt="Emirates ID" />
                                                                </Link>
                                                            )}
                                                            {technicianData.verificationDoc.TradeLicence && (
                                                                <Link className="pr-3" data-magnify="gallery" data-src="" data-caption="Trade Licence" data-group="a" to={technicianData.verificationDoc.TradeLicence}>
                                                                    <img src={technicianData.verificationDoc.TradeLicence} width="100" alt="Trade Licence" />
                                                                </Link>
                                                            )}
                                                            {technicianData.verificationDoc.Photograph && (
                                                                <Link className="pr-3" data-magnify="gallery" data-src="" data-caption="Photograph" data-group="a" to={technicianData.verificationDoc.Photograph}>
                                                                    <img src={technicianData.verificationDoc.Photograph} width="100" alt="Photograph" />
                                                                </Link>
                                                            )}
                                                        </>
                                                    </div>
                                                </div>
                                            ) : (
                                                <p>No Documents Uploaded</p>
                                            )}

                                            <h3 className="pt-4">Add Comments &amp; Reason</h3>

                                            {editedTechnician && (
                                                <div>
                                                    <form>
                                                        {/* <div className="form-group text-dark">
                                                            <label>ID</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="id"
                                                                value={technicianData.technicianId}
                                                                readOnly
                                                            />
                                                        </div> */}
                                                        <div className="form-group">
                                                            <label>Comments</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="comments"
                                                                value={editedTechnician.comments}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Rejection Reason</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="rejectionreason"
                                                                value={editedTechnician.rejectionreason}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="btn btn-warning text-center px-3 py-2 rounded text-white" onClick={handleUpdateTechnician}>Update</div>
                                                    </form>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default TechnicianDetailsPage
