import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../Components/leftsidebar';
import Topbar from '../../Components/topbar';
import Breadcrumb from '../../Components/breadcrumb';
import Footer from '../../Components/footer';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';


const AllWarranty = () => {

    const [warrantyPlans, setWarrantyPlans] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [planName, setPlanName] = useState('');
    const [tenure, setTenure] = useState('monthly');
    const [planMetricPercentage, setPlanMetricPercentage] = useState('');
    const [planMetricFixed, setPlanMetricFixed] = useState('');
    const [deductibles, setDeductibles] = useState([{ deductibleName: '', deductibleMetricPercentage: '', deductibleMetricFixed: '' }]);
    const [applicableto, setApplicableto] = useState([{ minamount: '', minamount: '' }]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.gadgetsreborn.com/getwarrantyplanapplicable');
                setWarrantyPlans(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);


    const handleEditClick = (plan) => {
        setEditMode(true);
        setSelectedPlan(plan);
        setPlanName(plan.planName);
        setTenure(plan.tenure);
        setPlanMetricPercentage(plan.planMetricPercentage);
        setPlanMetricFixed(plan.planMetricFixed);
        setDeductibles(plan.deductibles);
        setApplicableto(plan.applicableto);

    };

    const handleCloseModal = () => {
        setEditMode(false);
        setSelectedPlan(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`https://api.gadgetsreborn.com/updatePlan/${selectedPlan._id}`, {
                planName,
                tenure,
                planMetricPercentage,
                planMetricFixed,
                deductibles,
                applicableto,
            });
            // Update the state variables with the updated values
            setWarrantyPlans(warrantyPlans.map(plan => (plan._id === selectedPlan._id ? response.data : plan)));
            // Update the applicableto state with the new data
            setApplicableto(response.data.applicableto);
            // Close the modal
            handleCloseModal();
        } catch (error) {
            console.error('Error updating plan:', error);
        }
    };

    const handelDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this entry?")) {
            axios
                .delete(`https://api.gadgetsreborn.com/deletePlan/${id}`)
                .then((res) => {
                    window.location.reload();
                })
                .catch((error) => console.log(error));
        }
    };

    const handleDeleteApplicable = async (appId) => {
        if (window.confirm("Are you sure you want to delete this applicable entry?")) {
            try {
                // Send a request to delete the applicable entry by its ID
                await axios.delete(`https://api.gadgetsreborn.com/deleteApplicable/${appId}`);
                // Update the state to remove the deleted applicable entry
                setSelectedPlan(prevPlan => ({
                    ...prevPlan,
                    applicableto: prevPlan.applicableto.filter(app => app._id !== appId)
                }));
            } catch (error) {
                console.error('Error deleting applicable entry:', error);
            }
        }
    };



    // Yearly Plan Cost
    const calculateYearlyCost = (planMetricPercentage, minAmount, maxAmount) => {
        if (!planMetricPercentage || !minAmount || !maxAmount) {
            return 0;
        }
        const averageAmount = (parseFloat(minAmount) + parseFloat(maxAmount)) / 2;
        return averageAmount * (parseFloat(planMetricPercentage) / 100);
    };

    const calculateRepairCost = (deductibleMetricPercentage, applicable) => {
        console.log("Applicable:", applicable); // Log the applicable object

        // Check if deductibleMetricPercentage and applicable are not null or undefined
        if (!deductibleMetricPercentage || !applicable || !applicable.minamount || !applicable.maxamount) {
            return 0;
        }
        const averageDeductAmount = (parseFloat(applicable.minamount) + parseFloat(applicable.maxamount)) / 2;
        return averageDeductAmount * (parseFloat(deductibleMetricPercentage) / 100);
    };



    return (
        <div>
            <div id="wrapper">

                <Sidebar />

                <div id="content-wrapper" className="d-flex flex-column">
                    <div class="box">
                        <svg width="1038" height="861" viewBox="0 0 1038 861" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="-19" y="-159.085" width="1119" height="1119" rx="65" transform="rotate(-21.7644 -19 -159.085)" fill="#FDDF02"></rect>
                        </svg>
                    </div>

                    <div id="content">

                        <Topbar />

                        <div className="container-fluid">

                            <Breadcrumb />

                            <div className="row">
                                <div className="col-xl-12 text-right mb-3">
                                    <Link to="/add-warranty" className="btn btn-primary">
                                        Add Warranty
                                    </Link>
                                </div>
                                <div className="col-xl-12">
                                    <table className="table table-hover table-striped">
                                        <thead>
                                            <tr>
                                                <th>Plan Name</th>
                                                <th>Tenure</th>
                                                <th>Plan Metric</th>
                                                <th>Yearly Cost</th>
                                                <th>Deductible Name</th>
                                                <th>Deductible Metric</th>
                                                <th>Applicable to</th>
                                                <th>Min. Cost</th>
                                                <th>Max. Cost</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {warrantyPlans.map(plan => (
                                                <tr key={plan._id}>
                                                    <td>{plan.planName}</td>
                                                    <td>{plan.tenure}</td>
                                                    <td>
                                                        {plan.planMetricPercentage ? `${plan.planMetricPercentage}%` : plan.planMetricFixed ? `${plan.planMetricFixed} AED` : null}
                                                    </td>
                                                    <td>
                                                        {plan.applicableto.map(applicable => (
                                                            <div key={applicable._id}>
                                                                {plan.planMetricFixed ? plan.planMetricFixed.toFixed(2) + " AED" : calculateYearlyCost(plan.planMetricPercentage, applicable.minamount, applicable.maxamount).toFixed(2) + " AED"}
                                                            </div>
                                                        ))}
                                                    </td>
                                                    <td>
                                                        <ol>
                                                            {plan.deductibles.map(deductible => (
                                                                <li>{deductible.deductibleName}</li>
                                                            ))}
                                                        </ol>
                                                    </td>
                                                    <td>
                                                        <ol>
                                                            {/* {plan.deductibles.map(deductible => (
                                                                <li key={deductible._id}>
                                                                    {deductible.deductibleMetricPercentage ? `${deductible.deductibleMetricPercentage}%` : `${deductible.deductibleMetricFixed} AED`}
                                                                </li>
                                                            ))} */}


                                                            {plan.applicableto.map(applicable => (
                                                                <div key={applicable._id}>
                                                                    {plan.deductibles.map(deductible => (
                                                                        <li key={deductible._id}>
                                                                            {deductible.deductibleMetricFixed ? deductible.deductibleMetricFixed.toFixed(2) + " AED" : calculateRepairCost(deductible.deductibleMetricPercentage, applicable).toFixed(2) + " AED"}
                                                                        </li>
                                                                    ))}
                                                                </div>
                                                            ))}




                                                        </ol>
                                                    </td>
                                                    <td>
                                                        {plan.applicableto && plan.applicableto.length > 0 ? (
                                                            <div>
                                                                {plan.applicableto.map(applicable => (
                                                                    <div key={applicable._id}>
                                                                        {applicable.model.modelname}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : (
                                                            <div>No applicable data available</div>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {plan.applicableto.map(applicable => (
                                                            <div key={applicable._id}>
                                                                {applicable.minamount}
                                                            </div>
                                                        ))}

                                                    </td>
                                                    <td>
                                                        {plan.applicableto.map(applicable => (
                                                            <div key={applicable._id}>
                                                                {applicable.maxamount}
                                                            </div>
                                                        ))}
                                                    </td>
                                                    <td>
                                                        <div className="row">
                                                            <div className="col-3" key={plan._id}>
                                                                <button className="btn btn-link text-danger p-0" onClick={() => handleEditClick(plan)}>
                                                                    <i className="fas fa-pen text-dark" />
                                                                </button>
                                                            </div>
                                                            <div className="col-3">
                                                                <button className="btn btn-link text-danger p-0" onClick={() => handelDelete(plan._id)}>
                                                                    <i className="fas fa-trash text-danger" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>




            <Modal show={editMode} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Plan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formPlanName">
                            <div className="row mb-3">
                                <div className="col-xl-6">
                                    <Form.Label>Plan Name</Form.Label>
                                    <Form.Control type="text" value={planName} onChange={(e) => setPlanName(e.target.value)} />
                                </div>
                                <div className="col-xl-6">
                                    {planMetricPercentage ? (
                                        <>
                                            <Form.Label>Plan Metric%</Form.Label>
                                            <Form.Control type="text" value={planMetricPercentage} onChange={(e) => setPlanMetricPercentage(e.target.value)} />
                                        </>
                                    ) : (
                                        <>
                                            <Form.Label>Plan Metric Fixed</Form.Label>
                                            <Form.Control type="text" value={planMetricFixed} onChange={(e) => setPlanMetricFixed(e.target.value)} />
                                        </>
                                    )}
                                </div>
                            </div>


                            {deductibles.map((deductible, index) => (
                                <div key={index}>
                                    <div className="row mb-3">
                                        <div className="col-xl-6">

                                            <Form.Label>Deductible {index + 1}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={deductible.deductibleName}
                                                onChange={(e) => {
                                                    const updatedDeductibles = [...deductibles];
                                                    updatedDeductibles[index].deductibleName = e.target.value;
                                                    setDeductibles(updatedDeductibles);
                                                }}
                                            />
                                        </div>
                                        <div className="col-xl-6">
                                            {deductible.deductibleMetricPercentage ? (
                                                <>
                                                    <Form.Label>Deductible Metric %</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={deductible.deductibleMetricPercentage}
                                                        onChange={(e) => {
                                                            const updatedDeductibles = [...deductibles];
                                                            updatedDeductibles[index].deductibleMetricPercentage = e.target.value;
                                                            setDeductibles(updatedDeductibles);
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <Form.Label>Deductible Metric Fixed</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={deductible.deductibleMetricFixed}
                                                        onChange={(e) => {
                                                            const updatedDeductibles = [...deductibles];
                                                            updatedDeductibles[index].deductibleMetricFixed = e.target.value;
                                                            setDeductibles(updatedDeductibles);
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}


                            {/* Applicable Fields */}
                            <div className="d-flex justify-content-between">
                                <Form.Label>Applicable Fields</Form.Label>
                            </div>
                            {applicableto.map((applicable, index) => (
                                <div key={index}>
                                    {applicable.model && applicable.model.modelname ? (
                                        <>
                                            <div className="d-flex justify-content-between">
                                                <Form.Label>Modal Name</Form.Label>
                                                {selectedPlan && selectedPlan.applicableto && selectedPlan.applicableto.length > 0 && (
                                                    <button className="btn btn-link text-danger p-0" onClick={() => handleDeleteApplicable(applicable._id)}>
                                                        <i className="fas fa-trash text-danger" />
                                                    </button>
                                                )}
                                            </div>
                                            <Form.Control
                                                type="text"
                                                value={applicable.model.modelname}
                                                disabled
                                            />
                                        </>
                                    ) : null}

                                    <div className="row mb-3 mt-3">
                                        <div className="col-xl-6">
                                            <>
                                                <Form.Label>Min Amount</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={applicable.minamount}
                                                    onChange={(e) => {
                                                        const updatedApplicableto = [...applicableto];
                                                        updatedApplicableto[index].minamount = e.target.value;
                                                        setApplicableto(updatedApplicableto);
                                                    }}
                                                />
                                            </>
                                        </div>
                                        <div className="col-xl-6">
                                            <>
                                                <Form.Label>Max Amount</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={applicable.maxamount}
                                                    onChange={(e) => {
                                                        const updatedApplicableto = [...applicableto];
                                                        updatedApplicableto[index].maxamount = e.target.value;
                                                        setApplicableto(updatedApplicableto);
                                                    }}
                                                />
                                            </>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </Form.Group>
                        {/* Other form fields */}
                        <Button variant="primary" type="submit" >
                            Save Changes
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>




        </div>
    )
}

export default AllWarranty
