import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sidebar from '../../Components/leftsidebar';
import Topbar from '../../Components/topbar';
import Breadcrumb from '../../Components/breadcrumb';

const SearchWarranty = () => {
    const [selectedType, setSelectedType] = useState('');
    const [types, setTypes] = useState([]);
    const [brands, setBrands] = useState([]);
    const [series, setSeries] = useState([]);
    const [models, setModels] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedSeries, setSelectedSeries] = useState('');
    const [selectedModel, setSelectedModel] = useState('');
    const [warrantyPlans, setWarrantyPlans] = useState([]);
    const [selectedWarrantyPlan, setSelectedWarrantyPlan] = useState(null);

    useEffect(() => {
        fetchTypes();
    }, []);

    useEffect(() => {
        if (selectedType) {
            fetchBrandsByType(selectedType);
        }
    }, [selectedType]);

    useEffect(() => {
        if (selectedBrand) {
            fetchSeriesByBrand(selectedBrand);
        }
    }, [selectedBrand]);

    useEffect(() => {
        if (selectedSeries) {
            fetchModelsBySeries(selectedSeries);
        }
    }, [selectedSeries]);

    const fetchTypes = async () => {
        try {
            const response = await axios.get('https://api.gadgetsreborn.com/gettypes');
            setTypes(response.data);
        } catch (error) {
            console.error('Error fetching types:', error);
        }
    };

    const fetchBrandsByType = async (typeId) => {
        try {
            const response = await axios.get(`https://api.gadgetsreborn.com/getbrands/${typeId}`);
            setBrands(response.data);
        } catch (error) {
            console.error('Error fetching brands:', error);
        }
    };

    const fetchSeriesByBrand = async (brandId) => {
        try {
            const response = await axios.get(`https://api.gadgetsreborn.com/getseries/${brandId}`);
            setSeries(response.data);
        } catch (error) {
            console.error('Error fetching series:', error);
        }
    };

    const fetchModelsBySeries = async (seriesId) => {
        try {
            const response = await axios.get(`https://api.gadgetsreborn.com/getmodels/${seriesId}`);
            setModels(response.data);
        } catch (error) {
            console.error('Error fetching models:', error);
        }
    };

    const searchWarrantyPlans = async () => {
        try {
            const response = await axios.post('https://api.gadgetsreborn.com/searchwarrantyplans', {
                type: selectedType,
                brand: selectedBrand,
                series: selectedSeries,
                model: selectedModel
            });
            setWarrantyPlans(response.data);
        } catch (error) {
            console.error('Error searching warranty plans:', error);
        }
    };

    // Yearly Plan Cost
    const calculateYearlyCost = (planMetricPercentage, minAmount, maxAmount) => {
        if (!planMetricPercentage || !minAmount || !maxAmount) {
            return 0;
        }
        const averageAmount = (parseFloat(minAmount) + parseFloat(maxAmount)) / 2;
        return averageAmount * (parseFloat(planMetricPercentage) / 100);
    };

    const calculateRepairCost = (deductibleMetricPercentage, applicable) => {
        console.log("Applicable:", applicable); // Log the applicable object

        // Check if deductibleMetricPercentage and applicable are not null or undefined
        if (!deductibleMetricPercentage || !applicable || !applicable.minamount || !applicable.maxamount) {
            return 0;
        }
        const averageDeductAmount = (parseFloat(applicable.minamount) + parseFloat(applicable.maxamount)) / 2;
        return averageDeductAmount * (parseFloat(deductibleMetricPercentage) / 100);
    };

    return (
        <div>
            <div id="wrapper">

                <Sidebar />

                <div id="content-wrapper" className="d-flex flex-column">
                    <div class="box">
                        <svg width="1038" height="861" viewBox="0 0 1038 861" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="-19" y="-159.085" width="1119" height="1119" rx="65" transform="rotate(-21.7644 -19 -159.085)" fill="#FDDF02"></rect>
                        </svg>
                    </div>

                    <div id="content">

                        <Topbar />

                        <div className="container-fluid">

                            <Breadcrumb />

                            <div className="row">
                                <div className="col-xl-6 mx-auto">
                                    <div className="card shadow mb-4">
                                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h6 className="m-0 font-weight-bold text-primary">Search Plan</h6>
                                        </div>
                                        <div className="card-body">
                                            <form>
                                                <div className="d-flex justify-content-row">
                                                    <div className="mb-3 w-50 mr-3">
                                                        <label htmlFor="type">Type:</label>
                                                        <select id="type" className="form-control" value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
                                                            <option value="">Select Type</option>
                                                            {types.map(type => (
                                                                <option key={type._id} value={type._id}>{type.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="mb-3 w-50">
                                                        <label htmlFor="brand">Brand:</label>
                                                        <select id="brand" className="form-control" value={selectedBrand} onChange={(e) => setSelectedBrand(e.target.value)}>
                                                            <option value="">Select Brand</option>
                                                            {brands.map(brand => (
                                                                <option key={brand._id} value={brand._id}>{brand.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-row">
                                                    <div className="mb-3 mr-3 w-50">
                                                        <label htmlFor="series">Series:</label>
                                                        <select id="series" className="form-control" value={selectedSeries} onChange={(e) => setSelectedSeries(e.target.value)}>
                                                            <option value="">Select Series</option>
                                                            {series.map(series => (
                                                                <option key={series._id} value={series._id}>{series.seriesname}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="mb-3 w-50">
                                                        <label htmlFor="model">Model:</label>
                                                        <select id="model" className="form-control" value={selectedModel} onChange={(e) => setSelectedModel(e.target.value)}>
                                                            <option value="">Select Model</option>
                                                            {models.map(model => (
                                                                <option key={model._id} value={model._id}>{model.modelname}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={searchWarrantyPlans}
                                                >
                                                    Search
                                                </button>

                                                {warrantyPlans.length > 0 && (
                                                    <div className="mt-3">
                                                        <h4>Search Results:</h4>

                                                        {warrantyPlans.map(plan => (
                                                            <div key={plan._id}>
                                                                {plan.planName}
                                                                <button type="button" className="btn btn-primary ml-3" onClick={() => setSelectedWarrantyPlan(plan)}>Select</button>
                                                            </div>
                                                        ))}

                                                    </div>
                                                )}

                                                {selectedWarrantyPlan && (
                                                    <div className="mt-3">
                                                        <p className="font-weight-bold">Plan Name: {selectedWarrantyPlan.planName}</p>
                                                        {/* <p>Tenure: {selectedWarrantyPlan.tenure}</p> */}
                                                        {/* <p>Plan Metric Percentage: {selectedWarrantyPlan.planMetricPercentage}</p> */}
                                                        <p className="d-inline">
                                                            Plan Cost: {selectedWarrantyPlan.applicableto.map(applicable => (
                                                                <span key={applicable._id}>
                                                                    {selectedWarrantyPlan.planMetricFixed ? `${selectedWarrantyPlan.planMetricFixed.toFixed(2)} AED` : `${calculateYearlyCost(selectedWarrantyPlan.planMetricPercentage, applicable.minamount, applicable.maxamount).toFixed(2)} AED`}
                                                                </span>
                                                            ))}
                                                        </p>

                                                        <p>
                                                            {selectedWarrantyPlan.applicableto.map(applicable => (
                                                                <div key={applicable._id}>
                                                                    {selectedWarrantyPlan.deductibles.map(deductible => (
                                                                        <p className="mb-0" key={deductible._id}>{deductible.deductibleName}: {deductible.deductibleMetricFixed ? deductible.deductibleMetricFixed.toFixed(2) + " AED" : calculateRepairCost(deductible.deductibleMetricPercentage, applicable).toFixed(2) + " AED"}</p>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                        </p>



                                                        {/* <p>Deductible Metric Fixed: {selectedWarrantyPlan.deductibles.map(deductible => deductible.deductibleMetricFixed).join(', ')}</p> */}
                                                        {/* <p>Min Amount: {selectedWarrantyPlan.applicableto.map(applicable => applicable.minamount).join(', ')}</p> */}
                                                        {/* <p>Max Amount: {selectedWarrantyPlan.applicableto.map(applicable => applicable.maxamount).join(', ')}</p> */}
                                                    </div>
                                                )}



                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchWarranty