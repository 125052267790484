import React, { useEffect, useRef, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Sidebar from '../Components/leftsidebar';
import Topbar from '../Components/topbar';
import Breadcrumb from '../Components/breadcrumb';
import Footer from '../Components/footer';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ReactPaginate from 'react-paginate';
import axios from "axios";

export default function Company({ companyData }) {

    const [data, setData] = useState([]);
    const [limit, setLimit] = useState(10);
    const [showModal, setShowModal] = useState(false); // State to manage modal visibility
    const [showModall, setShowModall] = useState(false);
    const [showPassport, setShowPassport] = useState(false);
    const [showEid, setShowEid] = useState(false);
    const [showTl, setShowTl] = useState(false);
    const [showPhoto, setShowPhoto] = useState(false);
    const [selectedTechnician, setselectedTechnician] = useState(null); // State to store selected user
    const [editedTechnician, seteditedTechnician] = useState({});
    const [pageCount, setPageCount] = useState(3);
    const currentPage = useRef();

    useEffect(() => {
        currentPage.current = 1;
        getAllTechnicians();
        getPaginatedTechnicians();
    }, [])

    const getAllTechnicians = () => {
        fetch("https://api.gadgetsreborn.com/getAllCompany", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "bearer " + window.localStorage.getItem("token"),
            }
        })
            .then((res) => res.json())
            .then((data) => {
                // console.log(data, "userData");
                setData(data.data);
            });
    };

    // Function to trim the last 6 digits from _id
    const trimUserId = (id) => {
        if (id && id.length >= 6) {
            return id.slice(-6);
        }
        return id;
    }

    const formatDate = (createdAt) => {
        if (createdAt) {
            const date = new Date(createdAt);
            const day = date.getDate();
            const month = date.getMonth() + 1; // Months are 0-based
            const year = date.getFullYear();

            // Ensure day and month have two digits by adding leading zeros if needed
            const formattedDay = day < 10 ? `0${day}` : day;
            const formattedMonth = month < 10 ? `0${month}` : month;

            return `${formattedDay}/${formattedMonth}/${year}`;
        }
        return createdAt;
    }

    const handleOpenViewModal = (user) => {
        setselectedTechnician(user);
        setShowModall(true);
    };

    const handleOpenEditModal = (user) => {
        setselectedTechnician(user);
        seteditedTechnician(user); // Initialize the editedTechnician state with the selected user data
        setShowModal(true);
    };

    // Function to close the modal
    const handleCloseModal = () => {
        setShowModal(false);
        setShowModall(false);
    };

    const handleInputChange = (e) => {
        // Update the editedTechnician state when input fields change
        const { name, value } = e.target;
        seteditedTechnician({ ...editedTechnician, [name]: value });
    };

    const handlePageClick = (e) => {
        console.log(e);
        currentPage.current = e.selected + 1;
        getPaginatedTechnicians();
    };

    const getPaginatedTechnicians = () => {
        fetch(`https://api.gadgetsreborn.com/paginatedTechnicians?page=${currentPage.current}&limit=${limit}`, {
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data, "userData");
                setPageCount(data.pageCount);
                setData(data.result)
            });
    }


    // Helper function to capitalize the first letter of a string
    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    // Helper function to add a space after specific words
    const addSpaceAfterWord = (str) => {
        if (!str) return ''; // Return an empty string if str is undefined or null

        // Define the words after which you want to add a space
        const wordsWithSpace = ['repair', 'product'];

        // Replace each word in the string with the word followed by a space
        wordsWithSpace.forEach((word) => {
            str = str.replace(new RegExp(word, 'gi'), word.charAt(0).toUpperCase() + word.slice(1) + ' ');
        });

        return str;
    };

    const handelDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this entry?")) {
            axios
                .delete(`https://api.gadgetsreborn.com/companyVerify/${id}`)
                .then((res) => {
                    window.location.reload();
                })
                .catch((error) => console.log(error));
        }
    };


    return (
        <div>

            <div id="wrapper">

                <Sidebar />

                <div id="content-wrapper" className="d-flex flex-column">
                    <div className="box">
                        <svg width="1038" height="861" viewBox="0 0 1038 861" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="-19" y="-159.085" width="1119" height="1119" rx="65" transform="rotate(-21.7644 -19 -159.085)" fill="#FDDF02"></rect>
                        </svg>
                    </div>
                    <div id="content">

                        <Topbar />

                        <div className="container-fluid">

                            <Breadcrumb />

                            <div className="row">

                                <div className="col-xl-12 col-lg-12">
                                    <div className="card shadow mb-4">
                                        <div
                                            className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h6 className="m-0 font-weight-bold text-primary">All Registered Companies</h6>
                                        </div>
                                        <div className="card-body">
                                            <table className="table table-striped">
                                                <thead className="text-dark">
                                                    <tr>
                                                        <th width="10%">ID</th>
                                                        <th width="20%">Company Name</th>
                                                        <th width="25%">Phone & Email</th>
                                                        <th width="15%">Company Type</th>
                                                        <th width="10%">Trade License No</th>
                                                        <th width="10%">Region</th>
                                                        <th width="15%">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-dark">
                                                    {data.map(i => {
                                                        return (
                                                            <tr key={i._id}>
                                                                <td>{i.organizationId}</td>
                                                                <td>{i.companyname}</td>
                                                                <td>{i.phone} {i.companyemail}</td>
                                                                <td>{capitalizeFirstLetter(addSpaceAfterWord(i.companytype))}</td>
                                                                <td>{i.tradelicenseno}</td>
                                                                <td>{i.region}</td>
                                                                {/* <td>{i.isverified ?
                                                                    <div className="bg-success px-3 py-2 rounded text-white text-center">Verified</div>
                                                                    : <div className="btn btn-warning w-100" onClick={() => handleVerify(i._id)}>Verify</div>
                                                                }
                                                                </td> */}
                                                                <td>
                                                                    <div className="row">
                                                                        {/* <div className="col-3">
                                                                            <i className="fas fa-eye text-dark cursor-pointer" onClick={() => handleOpenViewModal(i)} />
                                                                        </div> */}
                                                                        <div className="col-3">
                                                                            {/* <i className="fas fa-eye text-dark cursor-pointer" onClick={() => handleOpenEditModal(i)} /> */}
                                                                            <Link to={`/company/${i._id}`} className="text-dark cursor-pointer">
                                                                                <i className="fas fa-eye text-dark cursor-pointer" />
                                                                            </Link>
                                                                        </div>
                                                                        <div className="col-3">
                                                                            <button
                                                                                className="btn btn-link text-danger p-0"
                                                                                onClick={() => handelDelete(i._id)}
                                                                            >
                                                                                <i className="fas fa-trash text-danger" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel="next >"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={5}
                                                pageCount={pageCount}
                                                previousLabel="< previous"
                                                renderOnZeroPageCount={null}
                                                marginPagesDisplayed={2}
                                                containerClassName="pagination justify-content-center"
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                activeClassName="active"
                                                forcePage={currentPage.current - 1}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

            <Link className="scroll-to-top rounded" to="#page-top">
                <i className="fas fa-angle-up" />
            </Link>


            <Modal show={showModal} onHide={handleCloseModal} className="text-dark">
                <Modal.Header closeButton>
                    <Modal.Title className="modal_font">Edit User Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {editedTechnician && (
                        <div>
                            <div className="form-group text-dark">
                                <p>Organization Id: {editedTechnician.organizationId}</p>
                                <p>Company Name: {editedTechnician.companyname}</p>
                                <p>Company Email: {editedTechnician.companyemail}</p>
                                <p>Company Type: {editedTechnician.companytype}</p>
                                <p>Company Address: {editedTechnician.companyaddress}</p>
                                <p>Owner Name: {editedTechnician.ownername}</p>
                                <p>Owner Emirates Id: {editedTechnician.owneremiratesId}</p>
                                <p>Phone: {editedTechnician.phone}</p>
                                <p>Trade License No: {editedTechnician.tradelicenseno}</p>
                                <p>Trade License Exp.: {editedTechnician.tradelicenseexp}</p>
                                <p>Region: {editedTechnician.region}</p>
                                <p>Owner Passport No: {editedTechnician.ownerpassportno}</p>
                                <p>Contact Person Name: {editedTechnician.contactpersonname}</p>
                                <p>Contact Person Phone Number: {editedTechnician.contactpersonno}</p>
                                <p>Company Location: {editedTechnician.companylocation}</p>
                                <p>Verified: {editedTechnician.isverified ? 'Yes' : 'No'}</p>
                                <p>Document Status: {editedTechnician.documentstatus ? 'Uploaded' : 'Not Available'}</p>
                                <p>
                                    Technicians List:
                                    <ul>
                                        {editedTechnician.technician?.map((technician, index) => (
                                            <li key={index}>
                                                Technician Id: {technician.technicianId}
                                            </li>
                                        ))}
                                    </ul>
                                </p>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>

    )
}