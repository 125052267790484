import React from "react";
import { useEffect, useRef, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Sidebar from '../Components/leftsidebar';
import Topbar from '../Components/topbar';
import Breadcrumb from '../Components/breadcrumb';
import Footer from '../Components/footer';
import axios from "axios";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import logo from './images/logo.png';

const OrderDetailsPage = () => {

    const { id } = useParams();
    const [orderData, setOrderData] = useState([]);
    const ref = useRef(null);


    useEffect(() => {
        viewOrderByID();
    }, []);

    const viewOrderByID = () => {
        fetch(`https://api.gadgetsreborn.com/orders/${id}`)
            .then((res) => res.json())
            .then((orderData) => {
                console.log(orderData);
                setOrderData(orderData);
            })
            .catch((error) => console.log(error));
    };


    const downloadPDF = () => {
        const input = ref.current;

        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const imgWidth = 190;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                // Add company logo
                pdf.addImage(logo, 'PNG', 10, 10, 50, 50); // Adjust position and size as needed

                // Add heading
                pdf.setFontSize(16);
                pdf.text("Diagnose Report", 70, 25); // Adjust position as needed

                // Add additional text
                pdf.setFontSize(12);
                pdf.text("Additional information goes here...", 10, 70); // Adjust position as needed

                // Add table content
                pdf.addImage(imgData, 'PNG', 10, 90, imgWidth, imgHeight);

                pdf.save("Diagnose-Report.pdf");
            });
    };


    return (
        <div>
            <div id="wrapper">
                <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div className="box">
                        <svg width="1038" height="861" viewBox="0 0 1038 861" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="-19" y="-159.085" width="1119" height="1119" rx="65" transform="rotate(-21.7644 -19 -159.085)" fill="#FDDF02"></rect>
                        </svg>
                    </div>
                    <div id="content">
                        <Topbar />
                        <div className="container-fluid">
                            <Breadcrumb />
                            <div className="row">
                                <div className="col-xl-5 col-lg-5">
                                    <div className="card shadow mb-4">
                                        <div
                                            className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h6 className="m-0 font-weight-bold text-primary">Order Details</h6>
                                        </div>
                                        <div className="card-body">
                                            <table className="table table-striped">
                                                <tbody className="text-dark">

                                                    <tr>
                                                        <th width="50%">Order Id:</th>
                                                        <td className="text-capitalize" width="50%">{orderData.orderId}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>User ID:</th>
                                                        <td className="text-capitalize">{orderData.userID}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Email:</th>
                                                        <td className="text-capitalize">{orderData.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Phone:</th>
                                                        <td className="text-capitalize">{orderData.phone}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>What Do You Like To Do: </th>
                                                        <td className="text-capitalize">{orderData.whatdoyouliketodo}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Device Type:</th>
                                                        <td className="text-capitalize"> {orderData.devicetype}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>What do you like to fix:</th>
                                                        <td className="text-capitalize">&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Booking Fee Paid:</th>
                                                        <td className="text-capitalize">{orderData.bookingfeepaid ? 'Paid' : 'Not Paid'}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Order Price:</th>
                                                        <td className="text-capitalize">{orderData.orderprice}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Due Amount:</th>
                                                        <td className="text-capitalize">{orderData.dueAmount}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Problem After Diagnose:</th>
                                                        <td className="text-capitalize">{orderData.problemAfterDiagnose}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Diagnose Done</th>
                                                        <td className="text-capitalize">{orderData.diagnoseDone}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Repair Method:</th>
                                                        <td className="text-capitalize">{orderData.pickupdrop}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Technician Allotted:</th>
                                                        <td className="text-capitalize">
                                                            {orderData.technicianAllotted}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Repair Date & Time:</th>
                                                        <td className="text-capitalize">{orderData.pickupdropdatetime}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-4">
                                    <div className="card shadow mb-4">
                                        <div
                                            className="card-header py-3">
                                            <h6 className="m-0 font-weight-bold text-primary">Address</h6>
                                        </div>
                                        <div className="card-body">
                                            <table className="table table-striped">
                                                <tbody className="text-dark">
                                                    <tr>
                                                        <th>House No</th>
                                                        <td>{orderData.address ? orderData.address.houseno : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Street Address</th>
                                                        <td>{orderData.address ? orderData.address.streetaddress : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Landmark</th>
                                                        <td>{orderData.address ? orderData.address.landmark : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>City</th>
                                                        <td>{orderData.address ? orderData.address.city : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Type</th>
                                                        <td>{orderData.address ? orderData.address.type : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Country</th>
                                                        <td>{orderData.address ? orderData.address.country : ""}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="card shadow mb-4">
                                        <div
                                            className="card-header py-3">
                                            <h6 className="m-0 font-weight-bold text-primary">Device Details</h6>
                                        </div>
                                        <div className="card-body">
                                            <table className="table table-striped">
                                                <tbody className="text-dark">
                                                    <tr>
                                                        <th>IMEI</th>
                                                        <td>{orderData.deviceDetails ? orderData.deviceDetails.imei : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Serial No.</th>
                                                        <td>{orderData.deviceDetails ? orderData.deviceDetails.serialnumber : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Type</th>
                                                        <td>{orderData.deviceDetails ? orderData.deviceDetails.type : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Brand</th>
                                                        <td>{orderData.deviceDetails ? orderData.deviceDetails.brand : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Series</th>
                                                        <td>{orderData.deviceDetails ? orderData.deviceDetails.series : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Modal</th>
                                                        <td>{orderData.deviceDetails ? orderData.deviceDetails.modal : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Color</th>
                                                        <td>{orderData.deviceDetails ? orderData.deviceDetails.color : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Memory</th>
                                                        <td>{orderData.deviceDetails ? orderData.deviceDetails.memory : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>OS Version</th>
                                                        <td>{orderData.deviceDetails ? orderData.deviceDetails.osversion : ""}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-3">
                                    <div className="card shadow mb-4">
                                        <div
                                            className="card-header py-3">
                                            <h6 className="m-0 font-weight-bold text-primary">Payment Details</h6>
                                        </div>
                                        <div className="card-body">
                                            <table className="table table-striped">
                                                <tbody className="text-dark">
                                                    <tr>
                                                        <th>Transaction Id</th>
                                                        <td>{orderData.paymentDetails ? orderData.paymentDetails.transactionId : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Amount</th>
                                                        <td>{orderData.paymentDetails ? orderData.paymentDetails.amount : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Payment Date</th>
                                                        <td>{orderData.paymentDetails ? orderData.paymentDetails.paymentDate : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Card Name</th>
                                                        <td>{orderData.paymentDetails ? orderData.paymentDetails.cardName : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Card Number</th>
                                                        <td>{orderData.paymentDetails ? orderData.paymentDetails.cardNumber : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Purpose</th>
                                                        <td>{orderData.paymentDetails ? orderData.paymentDetails.purpose : ""}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-12 col-lg-12">
                                    <div className="card shadow mb-4" ref={ref}>
                                        <div
                                            className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h6 className="m-0 font-weight-bold text-primary">Diagnos Report</h6>
                                            <button className="btn btn-warning btn-sm" onClick={downloadPDF}>Download PDF</button>
                                        </div>
                                        <div className="card-body">
                                            <table className="w-100">
                                                <tbody>
                                                    <tr>
                                                        <td width="33%">
                                                            <table className="table table-striped">
                                                                <tbody className="text-dark">
                                                                    <tr>
                                                                        <th>Carrier</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.carrier : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Grade</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.grade : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>A number</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.anumber : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Cycle Count</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.cyclecount : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Design Capacity</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.designcapacity : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Battery Health</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.batteryhealth : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Fmip</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.fmip : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>MDM</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.mdm : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Blacklist</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.blacklist : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Sim</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.sim : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Wiped Version</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.wipedversion : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Wiped Result</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.wipedresult : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>OS Version</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.osversion : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Operation</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.operation : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Touch</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.touch : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Earpiece</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.earpiece : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Digitizer</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.digitizer : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>iCloud</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.icloud : ""}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td width="33%">
                                                            <table className="table table-striped">
                                                                <tbody className="text-dark">
                                                                    <tr>
                                                                        <th>Front Camera</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.frontcamera : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Rear Camera Quality</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.rearcameraquality : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Sim Card</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.simcard : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>LCD</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.lcd : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Proximity</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.proximity : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>WiFi</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.wifi : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Tele Photo Camera Quality</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.telephotocameraquality : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>GPS</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.gps : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Front Video Recording</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.frontvideorecording : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Loud Speaker</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.loudspeaker : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Rear Video Recording</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.rearvideorecording : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Accelerometer</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.Accelerometer : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Device Vibration</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.devicevibration : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Bluetooth</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.bluetooth : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Gyroscope</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.gyroscope : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Power Button</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.powerbutton : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Brightness</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.brightness : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>&nbsp;</th>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td width="33%">
                                                            <table className="table table-striped">
                                                                <tbody className="text-dark">
                                                                    <tr>
                                                                        <th>Rear Mic</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.rearmic : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Rear Camera</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.rearcamera : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Front Camera Quality</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.frontcameraquality : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Screen Rotation</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.screenrotation : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Flash</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.flash : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Volume Down Button</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.volumedownbutton : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Flip Switch</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.flipswitch : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Buttom Mic</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.buttommic : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Volume Up Button</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.volumeupbutton : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Tele Photo Camera</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.telephotocamera : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Face id</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.faceid : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Buttons</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.buttons : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Fromt Mic</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.fromtmic : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Base Band</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.baseband : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Battery</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.battery : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Ambient Light</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.ambientlight : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Grading</th>
                                                                        <td>{orderData.diagnosis ? orderData.diagnosis.Grading : ""}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>&nbsp;</th>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default OrderDetailsPage;
