import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Dashboard from './Pages/Dashboard';
import ForgotPassword from './Pages/forgot-password';
import Users from './Pages/users';
import Technicians from './Pages/technicians';
import UsersProfile from './Pages/frontend/profile';
import TechnicianRegister from './Pages/TechnicianRegister';
import AddProduct from './Pages/product-management/add-product';
import AllProducts from './Pages/product-management/all-product';
import Modal from './Pages/product-management/modal';
import Warranty from './Pages/warranty/user-warranty';
import AddWarranty from './Pages/warranty/add-warranty';
import Notification from './Pages/notification/notification';
import TechnicianNotification from './Pages/notification/technician-notification';
import CompanyNotification from './Pages/notification/company-notification';
import Order from './Pages/recent-orders';
import Alldefects from './Pages/Alldefects';
import AddAlldevice from './Pages/addalldevice';
import Company from './Pages/Company';
import CompanyDetailsPage from './Pages/CompanyDetailsPage';
import OrderDetails from './Pages/OrderDetails';
import AllWarranty from './Pages/warranty/all-warranty';
import TechnicianDetailsPage from './Pages/TechnicianDetailsPage';
import Searchwarranty from './Pages/warranty/search-warranty';

function App() {
  const isLoggedIn = window.localStorage.getItem("loggedIn");

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={isLoggedIn === "true" ? <Dashboard /> : <Login />} exact />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/users" element={<Users />} />
        <Route path="/technicians" element={<Technicians />} />
        <Route path="/profile" element={<UsersProfile />} />
        <Route path="/technician-register" element={<TechnicianRegister />} />
        <Route path="/add-product" element={<AddProduct />} />
        <Route path="/all-products" element={<AllProducts />} />
        <Route path="/add-model" element={<Modal />} />
        <Route path="/user-warranty" element={<Warranty />} />
        <Route path="/add-warranty" element={<AddWarranty />} />
        <Route path="/warranty" element={<AllWarranty />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/technician-notification" element={<TechnicianNotification />} />
        <Route path="/company-notification" element={<CompanyNotification />} />
        <Route path="/order" element={<Order />} />
        <Route path="/all-defects" element={<Alldefects />} />
        <Route path="/company" element={<Company />} />
        <Route path="/company/:id" element={<CompanyDetailsPage />} />
        <Route path="/order/:id" element={<OrderDetails />} />
        <Route path="/technician/:id" element={<TechnicianDetailsPage />} />
        <Route path="/addalldevice" element={<AddAlldevice />} />
        <Route path="/searchwarranty" element={<Searchwarranty />} />
      </Routes>
    </div>
  );
}

export default App;
