import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../Components/leftsidebar';
import Topbar from '../../Components/topbar';
import Breadcrumb from '../../Components/breadcrumb';
import Footer from '../../Components/footer';
import axios from 'axios';

function WarrantyForm() {
    const [planName, setPlanName] = useState('');
    const [tenure, setTenure] = useState('monthly');
    const [planMetricPercentage, setPlanMetricPercentage] = useState('');
    const [planMetricFixed, setPlanMetricFixed] = useState('');
    const [deductibles, setDeductibles] = useState([{ deductibleName: '', deductibleMetricPercentage: '', deductibleMetricFixed: '' }]);
    const [minAmount, setMinAmount] = useState('');
    const [maxAmount, setMaxAmount] = useState('');

    const [existingPlans, setExistingPlans] = useState([]);
    const [getPlanName, setGetPlanName] = useState([]);
    const [type, setType] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [types, setTypes] = useState([]);
    const [brands, setBrands] = useState([]);
    const [seriess, setSeriess] = useState([]);
    const [models, setModels] = useState([]);
    const [brand, setBrand] = useState('');
    const [series, setSeries] = useState('');
    const [model, setModel] = useState('');
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedSeries, setSelectedSeries] = useState('');
    const [selectedModel, setSelectedModal] = useState('');

    useEffect(() => {
        const fetchExistingPlans = async () => {
            try {
                const response = await axios.get('https://api.gadgetsreborn.com/getwarrantyplan');
                setExistingPlans(response.data);
            } catch (error) {
                console.error('Error getting existing plans:', error);
            }
        };

        fetchExistingPlans();
    }, []);

    const handleAddDeductible = () => {
        setDeductibles([...deductibles, { deductibleName: '', deductibleMetricPercentage: '', deductibleMetricFixed: '' }]);
    };

    const handleDeleteDeductible = (index) => {
        const updatedDeductibles = deductibles.filter((_, idx) => idx !== index);
        setDeductibles(updatedDeductibles);
    };

    const handleDeductibleChange = (e, index, field) => {
        const updatedDeductibles = [...deductibles];
        updatedDeductibles[index][field] = e.target.value;
        setDeductibles(updatedDeductibles);
    };

    const handlePlanMetricChange = (e, field) => {
        if (field === 'percentage') {
            setPlanMetricPercentage(e.target.value);
            setPlanMetricFixed('');
        } else {
            setPlanMetricFixed(e.target.value);
            setPlanMetricPercentage('');
        }
    };

    const createWarrantyPlan = async (e) => {
        e.preventDefault();
        try {
            // Check for required fields
            if (!planName || !tenure || !(planMetricPercentage || planMetricFixed) || deductibles.some(deductible => !deductible.deductibleName || !(deductible.deductibleMetricPercentage || deductible.deductibleMetricFixed))) {
                alert('All Fields are required');
                return;
            }

            // Prepare data for the request
            const data = {
                planName,
                tenure,
                planMetricPercentage,
                planMetricFixed,
                deductibles
            };

            // Send the request
            const response = await axios.post('https://api.gadgetsreborn.com/warrantyplan', data);

            // Display success or failure message
            if (response.status === 201) {
                alert('Warranty Plan Added Successfully');
            } else {
                alert('Failed to add warranty plan');
            }

            // Clear form fields after submission
            setPlanName('');
            setTenure('monthly');
            setPlanMetricPercentage('');
            setPlanMetricFixed('');
            setDeductibles([{ deductibleName: '', deductibleMetricPercentage: '', deductibleMetricFixed: '' }]);
        } catch (error) {
            console.error('Error adding warranty plan:', error);
            alert('Something went wrong');
        }
    };


    // Fetch types on component mount
    useEffect(() => {
        fetchTypes();
    }, []);

    // Fetch brands when selectedType changes
    useEffect(() => {
        if (selectedType) {
            fetchBrandsByType(selectedType);
        }
    }, [selectedType]);

    // Fetch series when selectedBrand changes
    useEffect(() => {
        if (selectedBrand) {
            fetchSeriesByBrand(selectedBrand);
        }
    }, [selectedBrand]);

    // Fetch models when selectedSeries changes
    useEffect(() => {
        if (selectedSeries) {
            fetchModelsBySeries(selectedSeries);
        }
    }, [selectedSeries]);

    // Fetch types
    const fetchTypes = async () => {
        try {
            const response = await axios.get('https://api.gadgetsreborn.com/gettypes');
            setTypes(response.data);
        } catch (error) {
            console.error('Error fetching types:', error);
            // Add logic to handle error, such as displaying an error message to the user
        }
    };

    // Fetch brands by type
    const fetchBrandsByType = async (typeId) => {
        try {
            const response = await axios.get(`https://api.gadgetsreborn.com/getbrands/${typeId}`);
            setBrands(response.data);
        } catch (error) {
            console.error('Error fetching brands:', error);
        }
    };

    // Fetch series by brand
    const fetchSeriesByBrand = async (brandId) => {
        try {
            const response = await axios.get(`https://api.gadgetsreborn.com/getseries/${brandId}`);
            setSeriess(response.data);
        } catch (error) {
            console.error('Error fetching types:', error);
        }
    };

    // Fetch models by series
    const fetchModelsBySeries = async (seriesId) => {
        try {
            const response = await axios.get(`https://api.gadgetsreborn.com/getmodels/${seriesId}`);
            setModels(response.data);
        } catch (error) {
            console.error('Error fetching models:', error);
        }
    };

    const updateApplicableToPlan = async (e) => {
        e.preventDefault();
        try {
            const selectedPlan = existingPlans.find(plan => plan.planName === getPlanName);

            if (!selectedPlan) {
                alert('Please select a plan');
                return;
            }

            // Check if the selected combination already exists
            const combinationExists = selectedPlan.applicableto.some(entry => (
                entry.type === selectedType &&
                entry.brand === selectedBrand &&
                entry.series === selectedSeries &&
                entry.model === selectedModel
            ));

            if (combinationExists) {
                alert('This combination already applied on selected plan');
                return;
            }

            const data = {
                type: selectedType,
                brand: selectedBrand,
                series: selectedSeries,
                model: selectedModel,
                minamount: minAmount, // This should be passed to the backend
                maxamount: maxAmount, // This should be passed to the backend
            };

            const response = await axios.post(`https://api.gadgetsreborn.com/updateapplicableplan/${selectedPlan._id}`, data);

            if (response.status === 200) {
                alert('Applicable Plan Updated Successfully');

                // Clear form fields after successful submission
                setGetPlanName('');
                setType('');
                setBrand('');
                setSeries('');
                setModel([]);
                setSelectedType('');
                setSelectedBrand('');
                setSelectedSeries('');
                setSelectedModal('');
                setMinAmount('');
                setMaxAmount('');
            } else {
                alert('Failed to update applicable plan');
            }

        } catch (error) {
            console.error('Error updating applicable plan:', error);
            alert('Something went wrong');
        }
    };

    return (
        <div>
            <div id="wrapper">
                <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div className="box">
                        <svg width="1038" height="861" viewBox="0 0 1038 861" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="-19" y="-159.085" width="1119" height="1119" rx="65" transform="rotate(-21.7644 -19 -159.085)" fill="#FDDF02"></rect>
                        </svg>
                    </div>
                    <div id="content">
                        <Topbar />
                        <div className="container-fluid">
                            <Breadcrumb />
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="card shadow mb-4">
                                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h6 className="m-0 font-weight-bold text-primary">Create Plan</h6>
                                        </div>
                                        <div className="card-body">
                                            <form onSubmit={createWarrantyPlan}>
                                                <div className="mb-3">
                                                    <label htmlFor="typeName">Plan Name:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Plan Name"
                                                        id="planName"
                                                        value={planName}
                                                        onChange={(e) => setPlanName(e.target.value)}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <span>Tenure:</span>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            id="monthly"
                                                            value="monthly"
                                                            checked={tenure === 'monthly'}
                                                            onChange={() => setTenure('monthly')}
                                                        />
                                                        <label className="form-check-label" htmlFor="monthly">Monthly</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            id="yearly"
                                                            value="yearly"
                                                            checked={tenure === 'yearly'}
                                                            onChange={() => setTenure('yearly')}
                                                        />
                                                        <label className="form-check-label" htmlFor="yearly">Yearly</label>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-xl-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="planMetricPercentage">Plan Metric in %</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                placeholder="Enter Percentage Value"
                                                                id="planMetricPercentage"
                                                                value={planMetricPercentage}
                                                                onChange={(e) => handlePlanMetricChange(e, 'percentage')}
                                                                disabled={planMetricFixed !== ''}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="planMetricFixed">Plan Metric in Fixed</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                placeholder="Enter Fixed Value"
                                                                id="planMetricFixed"
                                                                value={planMetricFixed}
                                                                onChange={(e) => handlePlanMetricChange(e, 'fixed')}
                                                                disabled={planMetricPercentage !== ''}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {deductibles.map((deductible, index) => (
                                                    <div key={index} className="border border-top-0 border-left-0 border-right-0 mb-3 pb-3">
                                                        <div className="d-flex justify-content-between">
                                                            <label htmlFor={`deductibleName${index}`}>Deductible Name {index + 1}:</label>
                                                            {index > 0 && (
                                                                <Link
                                                                    to="#"
                                                                    className="text-danger"
                                                                    type="button"
                                                                    onClick={() => handleDeleteDeductible(index)}
                                                                >
                                                                    <i className="fas fa-trash text-danger" />
                                                                </Link>
                                                            )}
                                                        </div>

                                                        <input
                                                            type="text"
                                                            id={`deductibleName${index}`}
                                                            className="form-control"
                                                            placeholder="Enter Deductible Name"
                                                            value={deductible.deductibleName}
                                                            onChange={(e) => handleDeductibleChange(e, index, 'deductibleName')}
                                                        />
                                                        <div className="row my-3">
                                                            <div className="col-xl-6">


                                                                <label htmlFor={`deductiblePercentage${index}`}>Deductible Metric in %:</label>
                                                                <input
                                                                    type="number"
                                                                    id={`deductiblePercentage${index}`}
                                                                    className="form-control"
                                                                    placeholder="Enter Percentage Value"
                                                                    value={deductible.deductibleMetricPercentage}
                                                                    onChange={(e) => handleDeductibleChange(e, index, 'deductibleMetricPercentage')}
                                                                    disabled={deductible.deductibleMetricFixed !== ''}
                                                                />
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <label htmlFor={`deductibleFixed${index}`}>Deductible Metric in Fixed:</label>
                                                                <input
                                                                    type="number"
                                                                    id={`deductibleFixed${index}`}
                                                                    className="form-control"
                                                                    placeholder="Enter Fixed Value"
                                                                    value={deductible.deductibleMetricFixed}
                                                                    onChange={(e) => handleDeductibleChange(e, index, 'deductibleMetricFixed')}
                                                                    disabled={deductible.deductibleMetricPercentage !== ''}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                                <div className="row">
                                                    <div className="col-xl-12 text-right mb-3">
                                                        <Link to="#" onClick={handleAddDeductible}>Add More Deductible</Link>
                                                    </div>
                                                </div>

                                                <button type="submit" className="btn btn-primary">Save Plan</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="card shadow mb-4">
                                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h6 className="m-0 font-weight-bold text-primary">Applicable to</h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <form>
                                                        <div className="mb-3">
                                                            <label htmlFor="typeName">Plan:</label>
                                                            <select
                                                                id="type"
                                                                className="form-control"
                                                                value={getPlanName}
                                                                onChange={(e) => setGetPlanName(e.target.value)}
                                                            >
                                                                <option value="">Select Plan</option>
                                                                {existingPlans.map(plan => (
                                                                    <option key={plan._id} value={plan.planName}>{plan.planName}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="d-flex justify-content-row">
                                                            <div className="mb-3 w-50 mr-3">
                                                                <label htmlFor="type">Type:</label>
                                                                <select id="type" className="form-control" value={type} onChange={(e) => { setType(e.target.value); setSelectedType(e.target.value); }}>
                                                                    <option value="">Select Type</option>
                                                                    {types.map(type => (
                                                                        <option key={type._id} value={type._id}>{type.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="mb-3 w-50">
                                                                <label htmlFor="brand">Brand:</label>
                                                                <select id="brand" className="form-control" value={brand} onChange={(e) => { setBrand(e.target.value); setSelectedBrand(e.target.value); }}>
                                                                    <option value="">Select Brand</option>
                                                                    {brands.map(brand => (
                                                                        <option key={brand._id} value={brand._id}>{brand.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-row">
                                                            <div className="mb-3 mr-3 w-50">
                                                                <label htmlFor="series">Series:</label>
                                                                <select id="series" className="form-control" value={series} onChange={(e) => { setSeries(e.target.value); setSelectedSeries(e.target.value); }}>
                                                                    <option value="">Select Series</option>
                                                                    {seriess.map(series => (
                                                                        <option key={series._id} value={series._id}>{series.seriesname}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="mb-3 w-50">
                                                                <label htmlFor="Modal">Modal:</label>
                                                                <select id="model" className="form-control" value={model} onChange={(e) => { setModel(e.target.value); setSelectedModal(e.target.value); }}>
                                                                    <option value="">Select Modal</option>
                                                                    {models.map(model => (
                                                                        <option key={model._id} value={model._id}>{model.modelname}</option>
                                                                    ))}
                                                                </select>
                                                            </div>

                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-6 mb-3">
                                                                <label htmlFor="minAmount">Min. Amount:</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="Enter Min. Amount"
                                                                    value={minAmount}
                                                                    onChange={(e) => setMinAmount(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-xl-6 mb-3">
                                                                <label htmlFor="maxAmount">Max. Amount:</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="Enter Max. Amount"
                                                                    value={maxAmount}
                                                                    onChange={(e) => setMaxAmount(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary"
                                                            onClick={updateApplicableToPlan}
                                                        >
                                                            Update
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default WarrantyForm;
