import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../Components/leftsidebar';
import Topbar from '../Components/topbar';
import Breadcrumb from '../Components/breadcrumb';
import Footer from '../Components/footer';
import Modal from 'react-bootstrap/Modal';

export default function Users() {

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    const [showModall, setShowModall] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null); // State to store selected user
    const [technicians, setTechnicians] = useState([]);
    const [selectedTechnician, setselectedTechnician] = useState("");

    const [selectedTechnicianDetails, setSelectedTechnicianDetails] = useState(null);
    const currentPage = useRef();


    const handleOpenViewModal = async (order) => {
        setSelectedUser(order);
        setShowModall(true);

        if (order.technicianAllotted) {
            // Fetch technician details based on the technician ID
            const technicianDetails = await fetchTechnicianDetails(order.technicianAllotted);
            setSelectedTechnicianDetails(technicianDetails);
        }
    };

    // Function to close the modal
    const handleCloseModal = () => {
        setShowModall(false);
    };

    useEffect(() => {

        const token = window.localStorage.getItem("token");
        if (!token) {
            alert("Session End Login Again");
            window.localStorage.clear();
            window.location.href = "/";
        }

        getAllOrders();
        getAllTechnicians();
    }, []);

    const getAllOrders = async () => {
        fetch("https://api.gadgetsreborn.com/fetch-all-orders", {
            method: "GET",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "bearer " + window.localStorage.getItem("token"),
            },
        }).then((res) => res.json())
            .then((data) => {
                console.log(data);
                if (data.status === "ok") {
                    setOrders(data.data);
                    setLoading(false);
                }
            });
    }


    const formatDate = (createdAt) => {
        if (createdAt) {
            const date = new Date(createdAt);
            const day = date.getDate();
            const month = date.getMonth() + 1; // Months are 0-based
            const year = date.getFullYear();

            // Ensure day and month have two digits by adding leading zeros if needed
            const formattedDay = day < 10 ? `0${day}` : day;
            const formattedMonth = month < 10 ? `0${month}` : month;

            return `${formattedDay}/${formattedMonth}/${year}`;
        }
        return createdAt;
    }

    const formatTime = (createdAt) => {
        if (createdAt) {
            const date = new Date(createdAt);
            const hours = date.getHours();
            const minutes = date.getMinutes();
            // const seconds = date.getSeconds();

            // Ensure hours, minutes, and seconds have two digits by adding leading zeros if needed
            const formattedHours = hours < 10 ? `0${hours}` : hours;
            const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
            // const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

            return `${formattedHours}:${formattedMinutes}`;
        }
        return createdAt;
    };

    const getAllTechnicians = () => {
        fetch("https://api.gadgetsreborn.com/getAllVerifiedTechnicians", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "bearer " + window.localStorage.getItem("token"),
            }
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data, "userData");
                setTechnicians(data.data);
            });
    };

    const alloteTechnician = async (id) => {
        fetch("https://api.gadgetsreborn.com/allotTechnician", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "bearer " + window.localStorage.getItem("token"),
            },
            body: JSON.stringify({ technicianID: selectedTechnician, orderID: id }),
        })
            .then((res) => res.json())
            .then((data) => {
                alert(data.message);
                // Refresh the orders after successfully allotting the technician
                getAllOrders();
            });
    };

    const fetchTechnicianDetails = async (technicianId) => {
        const response = await fetch(`https://api.gadgetsreborn.com/getTechnicianDetails/${technicianId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "bearer " + window.localStorage.getItem("token"),
            },
        });

        const data = await response.json();
        return data.data; // Assuming the technician details are in the "data" property
    };



    return (
        <div>
            {console.log(selectedTechnician)}
            <div id="wrapper">
                <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div className="box">
                        <svg width="1038" height="861" viewBox="0 0 1038 861" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="-19" y="-159.085" width="1119" height="1119" rx="65" transform="rotate(-21.7644 -19 -159.085)" fill="#FDDF02"></rect>
                        </svg>
                    </div>
                    <div id="content">
                        <Topbar />
                        <div className="container-fluid">
                            <Breadcrumb />
                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card shadow mb-4">
                                        <div
                                            className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h6 className="m-0 font-weight-bold text-primary">All Orders</h6>
                                        </div>
                                        <div className="card-body">


                                            <table className="table table-striped">
                                                <thead className="text-dark">
                                                    <tr>
                                                        <th>ID</th>
                                                        {/* <th width="2%">Image</th> */}
                                                        <th>Job</th>
                                                        <th>Phone</th>
                                                        <th>Status</th>
                                                        <th>Date/Time</th>
                                                        <th>Technician Name</th>
                                                        <th>Dignose</th>
                                                        <th>Assign To</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>

                                                <tbody className="text-dark">
                                                    {loading ? (
                                                        <tr>
                                                            <td colspan="8">
                                                                <div className="d-block w-100 gradient element2"></div>
                                                            </td>
                                                        </tr>
                                                    ) : orders.length > 0 ? (
                                                        orders.map((order) => {
                                                            const technician = technicians.find((tech) => tech._id === order.technicianAllotted);
                                                            return (
                                                                <tr key={order.orderId} className="text-capitalize">
                                                                    <td>
                                                                        <a href="#" onClick={() => handleOpenViewModal(order)}>{order.orderId}</a>
                                                                    </td>
                                                                    <td>{order.whatdoyouliketodo}</td>
                                                                    <td>{order.phone}</td>
                                                                    <td>{order.orderstatus}</td>
                                                                    <td>{order.pickupdate} {order.pickuptime}</td>
                                                                    <td>{technician ? technician.email : "Not Allotted"}</td>
                                                                    <td>{order.diagnoseDone}</td>
                                                                    <td>
                                                                        {order.technicianAllotted ? (
                                                                            <div className="btn btn-success w-100">Allotted</div>
                                                                        ) : (
                                                                            <>
                                                                                <select className="form-control mb-3" onChange={(e) => setselectedTechnician(e.target.value)}>
                                                                                    <option>Select Option</option>
                                                                                    {technicians.map((verifiedTechy) => (
                                                                                        <option key={verifiedTechy._id} value={verifiedTechy._id}>{verifiedTechy.email}</option>
                                                                                    ))}
                                                                                </select>
                                                                                <Link to="#" className="btn btn-warning w-100" onClick={() => alloteTechnician(order.orderId)}>
                                                                                    Allote
                                                                                </Link>
                                                                            </>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <div className="row">
                                                                            {/* <div className="col-3">
                                                                            <i className="fas fa-eye text-dark cursor-pointer" onClick={() => handleOpenViewModal(i)} />
                                                                        </div> */}
                                                                            <div className="col-3">
                                                                                {/* <i className="fas fa-eye text-dark cursor-pointer" onClick={() => handleOpenEditModal(i)} /> */}
                                                                                <Link to={`/order/${order._id}`} className="text-dark cursor-pointer">
                                                                                    <i className="fas fa-eye text-dark cursor-pointer" />
                                                                                </Link>
                                                                            </div>
                                                                            <div className="col-3">
                                                                                <button
                                                                                    className="btn btn-link text-danger p-0"
                                                                                // onClick={() => handelDelete(i._id)}
                                                                                >
                                                                                    <i className="fas fa-trash text-danger" />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (
                                                        <tr>
                                                            <td colspan="8">No orders found</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

            <Link className="scroll-to-top rounded" to="#page-top">
                <i className="fas fa-angle-up" />
            </Link>


            <Modal show={showModall} onHide={handleCloseModal} className="text-dark">
                <Modal.Header closeButton>
                    <Modal.Title className="modal_font">Order Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedUser && (
                        <div className="row">
                            <div className="col-md-12 text-dark">
                                <table className="table table-striped text-capitalize">
                                    <thead>
                                        <tr>
                                            <th width="50%"></th>
                                            <th width="50%"></th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-dark">
                                        <tr>
                                            <td>Order ID:</td>
                                            <td>{selectedUser.orderId}</td>
                                        </tr>
                                        <tr>
                                            <td>what he/she like to do:</td>
                                            <td>{selectedUser.whatdoyouliketodo}</td>
                                        </tr>
                                        <tr>

                                            <td>Order Price:</td>
                                            <td>{selectedUser.orderprice}</td>
                                        </tr>
                                        <tr>
                                            <td>Due Amount: </td>
                                            <td>{selectedUser.dueAmount}</td>
                                        </tr>
                                        <tr>
                                            <td>Diagnose:</td>
                                            <td>{selectedUser.diagnoseDone}</td>
                                        </tr>
                                        <tr>
                                            <td>Diagnose Date:</td>
                                            <td>{selectedUser.diagnosticsdate.Date}{formatDate(selectedUser.diagnosticsdate)} - {selectedUser.diagnosticsdate.Date}{formatTime(selectedUser.diagnosticsdate)}</td>
                                        </tr>
                                        <tr>
                                            <td>Payment Details:</td>
                                            <td>{selectedUser.paymentDetails.transactionId}</td>
                                        </tr>
                                        <tr>
                                            <td>Order Status:</td>
                                            <td>{selectedUser.orderstatus}</td>
                                        </tr>
                                        <tr>
                                            <td>Order Date/Time:</td>
                                            <td>{selectedUser.lastorderDate.Date}{formatDate(selectedUser.lastorderDate)} - {selectedUser.lastorderDate.Date}{formatTime(selectedUser.lastorderDate)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-12 text-dark">
                                <table className="table table-striped text-capitalize">
                                    <thead>
                                        <tr>
                                            <th width="50%"><h4 className="text-dark">Work</h4></th>
                                            <th width="50%"></th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-dark">
                                        <tr>
                                            <td>Tasks:</td>
                                            <td>{selectedUser.work}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-12 text-dark">
                                <table className="table table-striped text-capitalize">
                                    <thead>
                                        <tr>
                                            <th width="50%"><h4 className="text-dark">Technician Details</h4></th>
                                            <th width="50%"></th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-dark">
                                        <tr>
                                            <td>Technician Name:</td>
                                            <td>{selectedTechnicianDetails ? selectedTechnicianDetails.fname : "Not Allotted"}</td>
                                        </tr>
                                        <tr>
                                            <td>Technician Email:</td>
                                            <td>{selectedTechnicianDetails ? selectedTechnicianDetails.email : "Not Allotted"}</td>
                                        </tr>
                                        <tr>
                                            <td>Technician Phone:</td>
                                            <td>{selectedTechnicianDetails ? selectedTechnicianDetails.phone : "Not Allotted"}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-12 text-dark">
                                <table className="table table-striped text-capitalize">
                                    <thead>
                                        <tr>
                                            <th width="50%"><h4 className="text-dark">User Details</h4></th>
                                            <th width="50%"></th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-dark">
                                        <tr>
                                            <td>User Email: </td>
                                            <td>{selectedUser.email}</td>
                                        </tr>
                                        <tr>
                                            <td>User Phone: </td>
                                            <td>{selectedUser.phone}</td>
                                        </tr>
                                        <tr>
                                            <td>Address: </td>
                                            <td>{selectedUser.address.houseno} {selectedUser.address.streetaddress}</td>
                                        </tr>
                                        <tr>
                                            <td>Pick/Drop:</td>
                                            <td>{selectedUser.pickupdrop}</td>
                                        </tr>
                                        <tr>
                                            <td>Pick Up Date/Time:</td>
                                            <td>{selectedUser.pickupdate} - {selectedUser.pickuptime}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseModal}>
                        Close
                    </button>
                    {/* Add a button to save updated user details */}
                    {/* Implement logic to update user details here */}
                </Modal.Footer>
            </Modal>
        </div>
    )
}